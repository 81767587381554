// interfaces
import { Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Auth } from "../interfaces/auth";

// utils
import { setValue } from "../utils/localStorage";
import { lazy, Suspense } from "react";
import { userAuthorization, userInfo } from "../utils/queries";
import { useQuery } from "react-query";
import { Switch, Route } from "react-router-dom";

// components
import { Progress } from "./Progress";
const License = lazy(() => import("./License"));
const Profiles = lazy(() => import("./Profiles"));
const Profile = lazy(() => import("./Profile"));
const Branches = lazy(() => import("./Branches"));
const Branch = lazy(() => import("./Branch/Branch"));
const CreateFreeBranch = lazy(() => import("./Branch/CreateFreeBranch"));
const Telephones = lazy(() => import("./Telephones/Telephones"));
const Spreads = lazy(() => import("./Spreads/Spreads"));
const Applications = lazy(() => import("./Applications/Applications"));
const Application = lazy(() => import("./Applications/Application"));
const FreeBranchCorrections = lazy(() => import("./FreeBranchCorrections/FreeBranchCorrections"));
const DevelopersDashboard = lazy(() => import("./DevelopersDashboard"));
const Reviews = lazy(() => import("./Reviews/Reviews"));
const Addresses = lazy(() => import("./Addresses"));
const Offices = lazy(() => import("./Offices"));
const NewOffices = lazy(() => import("./NewOffices"));

const setToLocalStorage = (data: Auth) => {
  setValue("access_token", data.access_token);
  setValue("refresh_token", data.refresh_token);
};


export const App = () => {
  useQuery<Auth>(["auth"], userAuthorization, {
    onSuccess: setToLocalStorage,
  });

  const { data, isLoading, isError } = useQuery<any>(["userInfo"], userInfo);

  if (isLoading) {
    return <Progress />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        Помилка авторизації! Необхідно виконати вхід на{" "}
        <Link href={process.env.REACT_APP_SITE_URL} target="_blank">
          minfin.com.ua
        </Link>
      </Alert>
    );
  }

  //? Закомментировать проверку, если у вас нет аккаунта администратора
  //! Раскомментировать для продакшена

  if (data && data.profile_type !== "admin") {
    return (
      <Alert severity="error">
        Ошибка доступа! Пользователь должен иметь права администратора
      </Alert>
    );
  }

  return (
    <>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/" exact component={Reviews} />
          <Route path="/reviews" exact component={Reviews} />
          <Route path="/corrections" exact component={FreeBranchCorrections} />
          <Route path="/applications" exact component={Applications} />
          <Route path="/applications/:id" exact component={Application} />
          <Route path="/license" exact component={License} />
          <Route path="/profiles" exact component={Profiles} />
          <Route path="/profiles/:id" exact component={Profile} />
          <Route path="/branches/" exact component={Branches} />
          <Route path="/branches/:id" exact component={Branch} />
          <Route path="/addresses" exact component={Addresses} />
          <Route path="/telephones" exact component={Telephones} />
          <Route path="/spreads" exact component={Spreads} />
          <Route path="/createFreeBranch" exact component={CreateFreeBranch} />
          <Route path="/developers" exact component={DevelopersDashboard} />
          <Route path="/offices" exact component={Offices} />
          <Route path="/new-offices" exact component={NewOffices} />
        </Switch>
      </Suspense>
    </>
  );
};
